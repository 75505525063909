:root {
  --yellow: #fccd35;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --dark-grey: #2b2b2b;
  --black: #000000;
}

.logo-main {
  position: relative;
  display: block;
  width: 90vw;
}

@media (min-width: 1024px) {
  .logo-main {
    width: 40vw;
  }
}

iframe {
  display: none !important;
}